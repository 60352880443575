<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";


const body = document.getElementsByTagName("body")[0];

const store = useStore();

onBeforeMount(() => {

  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");

});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <h1 class="mt-4 mb-4">Política de Privacidade - Agive Finanças</h1>
            <p>A sua privacidade é importante para nós. Esta Política de Privacidade explica como coletamos, usamos, compartilhamos e protegemos suas informações pessoais ao utilizar o aplicativo Agive Finanças. Ao utilizar nosso aplicativo, você concorda com as práticas descritas nesta política.</p>

            <h2>1. Informações que Coletamos</h2>
            <p>Quando você usa o Agive Finanças, podemos coletar os seguintes tipos de informações:</p>
            <ul class="ms-4">
                <li><strong>Informações Pessoais:</strong> Nome completo, endereço de e-mail, número de telefone (opcional).</li>
                <li><strong>Informações Financeiras:</strong> Detalhes de transações financeiras (entradas e saídas), saldo de contas, categorias de despesas e receitas, objetivos financeiros.</li>
                <li><strong>Informações Técnicas e de Uso:</strong> Logs de acesso e navegação no aplicativo.</li>
            </ul>

            <h2>2. Como Utilizamos Suas Informações</h2>
            <p>Usamos as informações coletadas para:</p>
            <ul class="ms-4">
                <li><strong>Fornecer e melhorar o serviço:</strong> Personalizar sua experiência, fornecer atualizações e melhorias contínuas no aplicativo.</li>
                <li><strong>Gerenciamento de contas e transações:</strong> Monitorar suas finanças e gerar relatórios de desempenho.</li>
                <li><strong>Comunicação:</strong> Enviar notificações importantes sobre o serviço, promoções e atualizações.</li>
                <li><strong>Segurança:</strong> Detectar atividades suspeitas, prevenir fraudes e proteger sua conta.</li>
            </ul>

            <h2>3. Compartilhamento de Informações</h2>
            <p>O Agive Finanças não venderá, alugará ou compartilhará suas informações pessoais com terceiros, exceto:</p>
            <ul class="ms-4">
                <li><strong>Com provedores de serviço de terceiros:</strong> Como plataformas de pagamento ou serviços de hospedagem, para manter o funcionamento do aplicativo.</li>
                <li><strong>Para fins legais:</strong> Quando exigido por lei ou para proteger os direitos, propriedade e segurança do Agive Finanças e seus usuários.</li>
            </ul>

            <h2>4. Segurança de Dados</h2>
            <p class="ms-4">Implementamos medidas de segurança físicas, eletrônicas e administrativas para proteger suas informações. No entanto, nenhum sistema é completamente seguro e não podemos garantir a segurança absoluta dos dados transmitidos através do aplicativo.</p>

            <h2>5. Direitos do Usuário</h2>
            <p>Você tem o direito de:</p>
            <ul class="ms-4">
                <li><strong>Acessar e atualizar suas informações:</strong> A qualquer momento, pode acessar e corrigir seus dados através do aplicativo.</li>
                <li><strong>Solicitar exclusão de dados:</strong> Pode solicitar que seus dados sejam excluídos permanentemente, sujeitando-se às exigências legais.</li>
                <li><strong>Revogar o consentimento:</strong> Pode revogar a permissão de coleta e uso de dados, mas isso poderá limitar a funcionalidade do aplicativo.</li>
            </ul>

            <h2>6. Retenção de Dados</h2>
            <p>Manteremos suas informações pessoais pelo tempo necessário para fornecer nossos serviços, cumprir obrigações legais, resolver disputas e aplicar nossos termos de uso.</p>

            <h2>7. Alterações nesta Política de Privacidade</h2>
            <p>Podemos atualizar esta Política de Privacidade periodicamente. Quando fizermos isso, iremos informá-lo através de notificações no aplicativo ou por e-mail. Recomendamos que você reveja esta política regularmente.</p>

            <h2>8. Contato</h2>
            <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco:</p>
            <p><strong>E-mail:</strong> equipe@agive.com.br</p>
            <p><strong>Endereço:</strong> Rua Copacabana, 362, Praia Grande, SP, Brasil</p>

            <p>Esta política foi atualizada pela última vez em 24/09/2024.</p>
            
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
