<script setup>

    import { onBeforeMount, onMounted } from "vue";
    import setTooltip from "../assets/js/tooltip";
    import router from "../router";
    import cookie from '../assets/js/cookies.js';
    import validSession from "../assets/js/valid-session.js";
    import { useStore } from "vuex";
    import axios from "axios";
    import { useRoute } from "vue-router";
    import SkeletonLoad from "./components/SkeletonLoad.vue";


    const store = useStore();
    const route = useRoute();

    onBeforeMount(() => {

        validSession.isLogged();

        store.state.card_load = true;

        store.state.validation = {
            nameHasError: false,
            nameMessageError: '',
            accountHasError: false,
            accountMessageError: '',
        };

        store.state.card = {
            icon: 'https://assets.dcsoftwares.com.br/imgs/cartoes/credit-card-logo.png',
            name: '',
            limit: 0,
            close_day: 1,
            due_day: 1,
            account: "",
            id: ''
        };

        store.state.card_details = {
            icon: '',
            name: '',
            limit: 0,
            limit_used: 0,
            close_day: 1,
            due_day: 1,
            account: "",
            id: '',
            fixeds: 0
        };

        store.state.icons = [];
        store.state.accounts = [];
        store.state.cards = [];
        store.state.invoices = [];
        store.state.qtdInvoices = 0;
        store.state.loadingCards = true;
        store.state.savingCard = false;
        store.state.editBalance = false;

        store.state.selectedYear = new Date().getFullYear();

        store.state.card_id = route.params.id ? route.params.id : 0;

        getCard(store.state.card_id);
    
    })

    onMounted(() => {
        setTooltip();

        let session = cookie.get('user_agive_session');

        if(session == null){
            router.push({name: 'Login'});
        }

    });

    const getInvoices = async (card, year) => {

        axios.get('invoices?card='+card+'&year='+year)
        .then(res => {
            
            if(res.status == 200){

                store.state.invoices = res.data.data;
                store.state.card_details.limit_used = parseFloat(store.state.card_details.limit) - (parseFloat(res.data.sum[0].current_amount) - parseFloat(store.state.card_details.fixeds ? store.state.card_details.fixeds : 0));
                
                store.state.card_load = false;

            }
            
        })
        .catch(err => {
            console.error(err); 
        })

    }

    const getCard = async(id) => {

        axios.get('credit-cards/show/'+id)
        .then(res => {
            
            if(res.status == 200){

                store.state.card_details = {
                    icon: res.data.icon,
                    name: res.data.name,
                    limit: res.data.limit,
                    close_day: res.data.close_day,
                    due_day: res.data.due_day,
                    id: res.data.id,
                    fixeds: res.data.future_fixed[0].amount
                };

                getInvoices(res.data.id, store.state.selectedYear);

            }
            
        })
        .catch(err => {
            console.error(err); 
        })

    }

    function formatMoney(v) {
        let total = parseFloat(v);

        return total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
    }

    const getMonthString = (full_date) =>{

        let date = new Date(full_date + 'T01:00:00');
        let month = date.toLocaleString('default', { month: 'long' });

        return month.charAt(0).toUpperCase() + month.slice(1);

    }

    const slideYear = (card, direction) => {

        store.state.card_load = true;

        if(direction == 'prev'){

            let prev_year = store.state.selectedYear - 1;
            
            store.state.selectedYear = prev_year;

            getInvoices(card, store.state.selectedYear);

        }
        else if(direction == 'next'){

            let next_year = store.state.selectedYear + 1;

            store.state.selectedYear = next_year;

            getInvoices(card, store.state.selectedYear);

        }

    }

    const valid_status = (status, type) => {

        if(status == 'Open'){

            if(type == 'class'){
                return 'secondary';
            }
            else if(type == 'title'){
                return 'Em aberto';
            }

        }
        else if(status == 'Closed'){

            if(type == 'class'){
                return 'warning';
            }
            else if(type == 'title'){
                return 'Fechada';
            }

        }
        else if(status == 'Paid'){

            if(type == 'class'){
                return 'success';
            }
            else if(type == 'title'){
                return 'Paga';
            }
            
        }
        else if(status == 'Late'){

            if(type == 'class'){
                return 'error';
            }else if(type == 'title'){
                return 'Em Atraso';
            }          
        }
        else if(status == 'Current'){

            if(type == 'class'){
                return 'info';
            }else if(type == 'title'){
                return 'Atual';
            }          
        }

    }

</script>

<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="card">
                    <div class="card-header pb-0">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-4 order-2 order-lg-1">
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <SkeletonLoad
                                            :width='"65px"'
                                            :height="'65px'"
                                            :class="'rounded-circle'"
                                            v-if="store.state.card_load"
                                        />
                                        <img v-else :src="store.state.card_details.icon" alt="" width="50" class="rounded-circle">
                                    </div>
                                    <div class="col-6">
                                        <SkeletonLoad
                                            :width='"120px"'
                                            :height="'30px'"
                                            v-if="store.state.card_load"
                                        />
                                        <h4 v-else>{{ store.state.card_details.name }}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 text-center order-1 order-lg-2 mt-1 mb-2 mt-lg-0 mb-lg-0">
                                <a @click="slideYear(store.state.card_details.id, 'prev')" class="cursor-pointer">
                                    <i class="fal fa-chevron-left me-5"></i>
                                </a>
                                <span>
                                    {{ store.state.selectedYear }}
                                </span>
                                <a @click="slideYear(store.state.card_details.id, 'next')" class="cursor-pointer">
                                    <i class="fal fa-chevron-right ms-5"></i>
                                </a>
                                <hr class="d-lg-none">
                            </div>
                            <div class="col-6 col-lg-4 order-3 text-end align-self-baseline">
                                
                            </div>
                            
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">

                        <div class="col-12">

                            <div class="row">

                                <div class="col-12 mt-3 mb-3">

                                    <div class="row text-center mt-3">
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Limite
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-lg-0'"
                                                v-if="store.state.card_load"
                                            />
                                            <p v-else class="text-dark mt-n3">
                                                <b>{{ formatMoney(store.state.card_details.limit) }}</b>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Disponível para uso
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-lg-0'"
                                                v-if="store.state.card_load"
                                            />
                                            <p v-else class="text-dark mt-n3">
                                                <b>{{ formatMoney(store.state.card_details.limit_used) }}</b>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Fechamento
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-lg-0'"
                                                v-if="store.state.card_load"
                                            />
                                            <p v-else class="text-dark mt-n3">
                                                <b>Dia {{ store.state.card_details.close_day }}</b>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Vencimento
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-lg-0'"
                                                v-if="store.state.card_load"
                                            />
                                            <p v-else class="text-dark mt-n3">
                                                <b>Dia {{ store.state.card_details.due_day }}</b>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>

                            <div class="row">

                                <div class="col-11 mx-auto mb-3">

                                    <div class="card">
                                        <div class="card-header pb-0">
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto'"
                                                v-if="store.state.card_load"
                                            />
                                            <h6 v-else>{{store.state.invoices.length}} Faturas</h6>
                                        </div>
                                        <div class="card-body px-0 pt-0 pb-2">
                                            <div class="table-responsive p-0">
                                            <table class="table align-items-center mb-0">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th
                                                            class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7"
                                                        >
                                                            Mês
                                                        </th>
                                                        <th
                                                            class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2"
                                                        >
                                                            Situação
                                                        </th>
                                                        <th
                                                            class="text-center text-uppercase text-dark text-xxs font-weight-bolder opacity-7"
                                                        >
                                                            Valor da fatura
                                                        </th>
                                                        <th
                                                            class="text-center text-uppercase text-dark text-xxs font-weight-bolder opacity-7"
                                                        >
                                                            Valor Pago
                                                        </th>
                                                        <th class="text-dark opacity-7"></th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="store.state.card_load" class="text-center">
                                                    <tr>
                                                        <td>
                                                            <SkeletonLoad
                                                                :width='"90px"'
                                                                :height="'20px'"
                                                                :class="'mx-auto'"                                                      
                                                            />
                                                        </td>
                                                        <td>
                                                            <SkeletonLoad
                                                                :width='"90px"'
                                                                :height="'20px'"
                                                                :class="'mx-auto'"                                                            
                                                            />
                                                        </td>
                                                        <td class="align-middle text-center text-sm">
                                                            <SkeletonLoad
                                                                :width='"90px"'
                                                                :height="'20px'"
                                                                :class="'mx-auto'"                                                          
                                                            />
                                                        </td>
                                                        <td class="align-middle text-center">
                                                            <SkeletonLoad
                                                                :width='"90px"'
                                                                :height="'20px'"
                                                                :class="'mx-auto'"                                                           
                                                            />
                                                        </td>
                                                        
                                                        <td class="align-middle">
                                                            
                                                        </td>
                                                    </tr>
                                                
                                                </tbody>
                                                <tbody v-else class="text-center">
                                                    <tr v-for="(invoice, index) in store.state.invoices" :key="index">
                                                        <td>
                                                            <h6 class="mb-0 text-sm">{{ getMonthString(invoice.due_date) }}</h6>
                                                        </td>
                                                        <td>
                                                            <span 
                                                                class="badge badge-sm"
                                                                :class="'bg-gradient-' + valid_status(invoice.status, 'class')"
                                                            >
                                                                {{ valid_status(invoice.status, 'title') }}
                                                            </span>
                                                        </td>
                                                        <td class="align-middle text-center text-sm">
                                                            <span class="text-dark text-xs font-weight-bold">
                                                                {{ formatMoney((parseFloat(invoice.current_amount) + parseFloat(invoice.before_amount))) }}
                                                            </span>
                                                        </td>
                                                        <td class="align-middle text-center">
                                                            <span class="text-dark text-xs font-weight-bold">
                                                                {{ formatMoney(invoice.payed_amount) }}
                                                            </span>
                                                        </td>
                                                        
                                                        <td class="align-middle">
                                                            <a v-if="invoice.current_amount > 0" @click="payTrasaction(transaction.id, transaction.status)" class="me-3 cursor-pointer">
                                                                <i 
                                                                    class="fa-thumbs-up row-transactions-icons-actions"
                                                                    :class="invoice.status != 'Paid' ? 'fal text-black-50' : 'fas text-success'"
                                                                    v-tooltip="{
                                                                        content: invoice.status != 'Paid' ? 'Pagar' : 'Pago',
                                                                        distance: 15
                                                                    }"
                                                                >
                                                                </i>
                                                            </a>
                                                            <a :href="'/fatura/'+invoice.id" class="me-3 cursor-pointer">
                                                                <i 
                                                                    class="fal fa-file-invoice row-transactions-icons-actions text-black-50"
                                                                    v-tooltip="{
                                                                        content: 'Ver fatura',
                                                                        distance: 15
                                                                    }"
                                                                >
                                                                </i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
