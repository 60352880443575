<script setup>
defineProps({
    bank: {
        type: String,
        required: true
    },
    balance: {
        type: Number,
        required: true,
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    status: {
        type: String,
        default: "text-dark"
    }
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
</script>

<template>
    <div class="row align-items-center row-accounts">

        <div class="col-6 align-content-between d-flex align-items-center">
            <img :src="icon" alt="Banco Logo" width="48" class="rounded-circle ms-2 me-3">
            <h6 class="mt-n1 mb-n1">{{ bank }}</h6>
        </div>
        <div class="col-6 text-end">

            <span :class="status" class="me-3">
                <b>
                    {{ formatMoney(balance) }}
                </b>
            </span>

        </div>

    </div>
</template>

<style>
    .row-accounts{
        min-height: 70px;
    }
</style>