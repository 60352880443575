<script setup>

import AccountsRow from './components/AccountsRow.vue';
import { onBeforeMount, onMounted } from "vue";
import setTooltip from "../assets/js/tooltip";
import router from "../router";
import cookie from '../assets/js/cookies.js';
import validSession from "../assets/js/valid-session.js";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { useStore } from "vuex";
import axios from "axios";
import { toast } from 'vue3-toastify';
import loadGif from "@/assets/img/loading.gif"

const value_balance = {
    value: 0
};

const money = {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }

const getIcons = async () => {

    axios.get('bank-icons?type=account')
    .then(res => {
        
        if(res.status == 200){

            store.state.icons = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const store = useStore();

onBeforeMount(() => {

    validSession.isLogged();

    store.state.validation = {
        nameHasError: false,
        nameMessageError: '',
    };

    store.state.account = {
        icon: 'https://assets.dcsoftwares.com.br/imgs/bancos/banco-logo.png',
        name: '',
        not_sum: false,
        id: '',
        balance: ''
    };

    store.state.icons = [];
    store.state.accounts = [];
    store.state.loadingAccounts = true;
    store.state.editBalance = false;

    store.state.toArchive = {
        icon: '',
        name: '',
    };

    getIcons();
    getAccounts();
  
})

onMounted(() => {
    setTooltip();

    let session = cookie.get('user_agive_session');

    if(session == null){
        router.push({name: 'Login'});
    }

});

const clearFields = () => {
    store.state.account = {
        icon: 'https://assets.dcsoftwares.com.br/imgs/bancos/banco-logo.png',
        name: '',
        not_sum: false,
        id: '',
        balance: 0
    };

    store.state.editBalance = false;

    store.state.toArchive = {
        icon: '',
        name: '',
    };
}

const getAccounts = async () => {

    store.state.loadingAccounts = true;

    axios.get('accounts')
    .then(res => {
        
        if(res.status == 200){

            store.state.accounts = res.data;
            store.state.loadingAccounts = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const submitAccount = async () => {

    let data = new FormData();

    store.state.validation.nameHasError = false;

    data.append('name', store.state.account.name);
    data.append('icon', store.state.account.icon);
    data.append('sum', store.state.account.not_sum == true ? 'No' : 'Yes');

    let url = '/accounts';
    let toast_message = 'Conta criada com sucesso!';

    if(store.state.account.id != ''){

        store.state.account.balance = value_balance.value.replace('R$ ', '');

        data.append('balance', store.state.account.balance);

        url = '/accounts/update/'+store.state.account.id;
        toast_message = 'Conta atualizada com sucesso!';
    }

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'name'){
                    store.state.validation.nameHasError = true;
                    store.state.validation.nameMessageError = value[1];
                }

            })

        }
        else{

            store.state.validation = {
                nameHasError: false,
                nameMessageError: '',
            };

            closeModal('addAccount');

            clearFields();

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            getAccounts();

        }

    })
    .catch(err => {
        console.error(err); 
    })

}

const editAccount = (data) => {

    value_balance.value = formatMoney(data.balance);

    store.state.account = {
        icon: data.icon,
        name: data.name,
        not_sum:  data.sum == 'Yes' ? false : true,
        id: data.id,
        balance: data.balance
    };

}

const archiveAccount = async (account, archive) => {

    if(archive == 'Yes'){

        store.state.toArchive = {
            icon: account.icon,
            name: account.name,
        };

        openModal('confirmArchiveAccount');

        var confirmButton = document.getElementById('confirmArchiveAccountButton');
        confirmButton.addEventListener('click', async function () {
        // do something...
        
            let data = new FormData();

            data.append('archived', archive);

            let url = '/accounts/archive/'+account.id;
            let toast_message = 'Conta arquivada com sucesso!';

            await axios.post(url, data)
            .then(res => {

                if(res.data.error){

                    console.log(res);

                }
                else{

                    toast(toast_message, {
                        autoClose: 1000,
                        theme: "auto",
                        type: "success",
                        transition: "slide"
                    });

                    closeModal('confirmArchiveAccount');
                    
                    getAccounts();

                }

            })
            .catch(err => {
                console.error(err); 
            })
            

        })

    }
    else{

        let data = new FormData();

        data.append('archived', archive);

        let url = '/accounts/archive/'+account.id;
        let toast_message = 'Conta desarquivada com sucesso!';

        await axios.post(url, data)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                closeModal('confirmArchiveAccount');
                
                getAccounts();

            }

        })
        .catch(err => {
            console.error(err); 
        })

    }    

}

const deleteAccount = async (account) => {

    store.state.toArchive = {
        icon: account.icon,
        name: account.name,
    };

    openModal('deleteAccount');

    var confirmButton = document.getElementById('confirmExclusionAccountButton');
    confirmButton.addEventListener('click', async function () {
    // do something...

        let url = '/accounts/delete/'+account.id;
        let toast_message = 'Conta excluída com sucesso!';

        await axios.post(url)
        .then(res => {

            if(res.data.error){

                console.log(res);

            }
            else{

                toast(toast_message, {
                    autoClose: 1000,
                    theme: "auto",
                    type: "success",
                    transition: "slide"
                });

                closeModal('deleteAccount');
                
                getAccounts();

            }

        })
        .catch(err => {
            console.error(err); 
        })
        

    })

}

const openModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.show();

}

const closeModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.hide();

}

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }

</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="card">
                    <div class="card-header pb-0">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <h6>Contas</h6>
                            </div>
                            <div class="col-6 text-end">
                                <a 
                                    class="me-3 trasation-list-add-button mt-n1 mb-2 text-dark" 
                                    type="button" 
                                    id="dropdownMenuButton1"
                                    @click="openModal('addAccount')"
                                    >
                                    <i class="fal fa-plus-circle"></i> <span class="d-none d-lg-inline-flex">Acidionar</span>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">

                        <div class="col-12">

                            <div class="row">

                                <div class="col-12 text-center mt-4" v-if="store.state.loadingAccounts">
                                    <img :src="loadGif" alt="" width="60px">
                                </div>
                                <div class="col-12 text-center mt-4" v-if="store.state.accounts.length == 0 && !store.state.loadingAccounts">
                                    <p>Nenhuma conta cadastrada.</p>
                                </div>
                                <div class="col-12 mt-4 mb-4" v-if="!store.state.loadingAccounts">

                                    <AccountsRow
                                        v-for="(account, index) in store.state.accounts" :key="index"
                                        :bank="account.name"
                                        :id="account.id"
                                        :icon="account.icon"
                                        :edit="account"
                                        @edit="editAccount"
                                        @archive="archiveAccount"
                                        @delete="deleteAccount"
                                    />

                                </div>

                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="addAccount" tabindex="-1" aria-labelledby="addAccount" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-10">
                            <b>
                                Nova conta
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-11 mx-auto">

                            <div class="row mt-4 mb-4 align-items-center">

                                <div class="col-3 account-create-icon-div">

                                    <img :src="store.state.account.icon" alt="" class="img-fluid rounded-circle">

                                </div>
                                <div class="col-9">
                                    <div class="row">

                                        <div class="col-12 mb-3">
                                            <label for="accountName">Nome da conta</label>
                                            <input 
                                                type="text" 
                                                class="form-control form-control-lg" 
                                                id="accountName"
                                                name="accountName"
                                                placeholder="Nome da conta"
                                                v-model="store.state.account.name"
                                                :class="store.state.validation.nameHasError ? 'is-invalid' : ''"
                                            >
                                            <div id="categoryNameFeedback" class="invalid-feedback">
                                                {{ store.state.validation.nameMessageError }}
                                            </div>
                                        </div>

                                        <div v-if="store.state.account.id > 0 && !store.state.editBalance" class="col-12 mb-3">
                                            <h4>
                                                {{formatMoney(store.state.account.balance)}} 
                                                <span>
                                                    <i 
                                                        class="fal fa-pencil text-black-50 row-accounts-list-icons-actions cursor-pointer"
                                                        v-tooltip="{
                                                                    content:'Editar Saldo',
                                                                    distance: 15
                                                                }"
                                                        @click="store.state.editBalance = true"
                                                        
                                                    >
                                                    </i>
                                                </span>
                                            </h4>
                                        </div>

                                        <div v-if="store.state.account.id > 0 && store.state.editBalance" class="col-12 mb-3">
                                            <label for="accountName">Saldo</label>
                                            <input 
                                                type="tel" 
                                                class="form-control form-control-lg" 
                                                id="accountName"
                                                name="accountName"
                                                placeholder="Saldo da conta"
                                                v-model.lazy="value_balance.value" 
                                                v-money="money"
                                            >
                                            <p class="text-sm">
                                                Digite - para saldo negativo
                                            </p>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="store.state.account.not_sum">
                                                <label class="form-check-label" for="flexCheckDefault" 
                                                v-tooltip="{
                                                                content: 'Marque essa opção para que o saldo desta conta não seja somado ao <b>Saldo Geral</b>', 
                                                                html: true,
                                                                distance: 15
                                                            }">
                                                    Não somar no Saldo Geral
                                                </label>
                                            </div>
                                        </div>    

                                    </div>
                                </div>                            

                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-12">
                                    <p>Escolha um icone</p>
                                </div>

                                <div class="col-12 icon-list">

                                    <div class="row justify-content-center">

                                        <div
                                            v-for="(icon, index) in store.state.icons" :key="index"
                                            @click="store.state.account.icon = icon.path"
                                            class="icon-picker d-flex align-items-center text-dark" 
                                            :title="icon.name"
                                        >
                                            <img :src="icon.path" :alt="icon.name" class="rounded-circle">
                                        </div>

                                    </div>

                                </div>
                                
                            </div>

                            <div class="row mt-2 mb-2">
                                <hr>
                            </div>

                            <div class="row">
                                
                            </div>

                            <div class="row mt-3">
                                <div class="col-12 text-center">
                                    <button
                                        @click="submitAccount()"
                                        class="btn btn-success"
                                    >
                                    {{ store.state.account.id == '' ? "Adicionar conta" : "Salvar Alterações" }}
                                    </button>
                                </div>
                            </div>

                        </div>

                        

                        

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="confirmArchiveAccount" tabindex="-1" aria-labelledby="confirmArchiveAccount" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-11 mx-auto mt-4 mb-4">
                            <h5 class="mb-4">Você quer mesmo arquivar essa conta?</h5>

                            <div class="col-12 text-center mt-3 mb-3">
                                <img :src="store.state.toArchive.icon" alt="" width="60" class="rounded">
                                <br>
                                <h4>{{store.state.toArchive.name}}</h4>
                            </div>

                            <div class="col-12 col-lg-10 mx-auto text-start mt-3 mb-3">
                                <p>
                                    <b>Não será mais possível</b>
                                    <ul>
                                        <li>Incluir novas transações</li>
                                        <li>Emitir relatórios</li>
                                        <li>Fazer filtros</li>
                                    </ul>
                                </p>
                                <p>
                                    Apesar disso, todas as transações continuarão disponíveis para manutenção do histórico financeiro.
                                    <br>
                                    <br>
                                    Você poderá reverter o arquivamento a qualquer momento.
                                </p>
                            </div>

                        </div>
                        <div class="col-12 text-center">
                            <button
                                id="confirmArchiveAccountButton" 
                                class="btn btn-success"
                            >
                                Arquivar conta
                            </button>

                            <a @click="closeModal('confirmArchiveAccount')" class="btn btn-link">Cancelar</a>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteAccount" tabindex="-1" aria-labelledby="deleteCategoryModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="col-11 mx-auto mt-4 mb-4">
                        <h5 class="mb-4">Você quer mesmo excluir essa conta?</h5>

                        <div class="col-12 text-center mt-3 mb-3">
                            <img :src="store.state.toArchive.icon" alt="" width="60" class="rounded">
                            <br>
                            <h4>{{store.state.toArchive.name}}</h4>
                        </div>

                        <div class="col-12 col-lg-10 mx-auto text-start mt-3 mb-3">
                            <p>
                                <b>Exclusão permanente de conta</b>
                                <ul>
                                    <li>Exclui todos os lançamentos feitos nessa conta;</li>
                                    <li>Todo este histórico financeiro será excluído;</li>
                                    <li>Não será possível recuperar esta conta.</li>
                                </ul>
                            </p>
                            <p>
                                <b>Atenção!</b> Esta operação é irreversível!
                            </p>
                        </div>

                    </div>
                    <div class="col-12 text-center">
                        <button
                            id="confirmExclusionAccountButton" 
                            class="btn btn-danger"
                        >
                            Excluir permanentemente
                        </button>

                        <a @click="closeModal('deleteAccount')" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

</template>

<style>

    .account-create-icon-div{
        height: 50px;
    }

    .account-create-icon{
        min-height: 100%;
    }

    .icon-picker{
        margin: 2px 4px 2px 0;
        width: 60px !important;
        height: 60px !important;
        cursor: pointer;
    }

    .icon-picker img {
        width: 100%;
        border-radius: 0.25rem
    }

    .icon-create{
        font-size: 30px;
    }

    .icon-list{
        height: 150px;
        overflow-x:auto;
    }

    .cursor-pointer{
        cursor: pointer;
    }

    @media (max-width: 575.98px) {

        .category-create-icon-div{
            height: 60px;
        }
    }
</style>